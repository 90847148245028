const featureFlags = [
  'paid_subscription_plan',
  'payment_3ds',
  'vn_van500_van1000', // to be removed in https://gogotech.atlassian.net/browse/BET-1903
  'paid_subscription_landing_page',
  'reload_page_message',
  'transport_tips',
  'inventory',
  'delivery_integration',
  'onboarding',
  'order_requests_api_revamp',
  'feature_tour',
  'login_mobile_view',
  'next_day_delivery',
  'shopify_integration',
  'new_delivery_types_in_history',
  'report_courier-t',
  'temp_vn_new_transport_additional_option',
  'disable_new_users_visiting_inner_pages',
  'ggb_web_transport_nid',
  'show_premium_service_label',
  'enable_coupon_feature',
  'vn_show_update_consent_timestamp_dialog',
  'multiple_credit_card',
  'transport_epod_photo',
  'sidebar_enhancement',
  'enable_transaction_filter',
  'new_login_ui',
  'new_login_ui_ab_testing',
  'new_users_and_branches_ui',
  'enable_address_book',
  'resizeable_drawers',
]

export { featureFlags }
