import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'

const DraggingSpace = styled.div`
  position: absolute;
  top: 0;
  left: -5px;
  height: 100%;
  width: 10px;
  padding: 0 4px;
  cursor: col-resize;
  &::before {
    content: ' ';
    display: block;
    width: 2px;
    height: 100%;
  }
  &:hover::before {
    background: ${COLOR.BLUE_DARK};
  }
`

const insertionOfResizingSpace = {
  adjustment: {
    drawerStyle: {
      padding: '0',
      overflowY: 'inherit',
    },
    containerStyle: `
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 28px;
    `,
  },
  ResizingSpace: () => {
    const spaceRef = useRef()
    const prevClientXRef = useRef()
    const [dragging, setDragging] = useState(false)
    useEffect(() => {
      if (dragging) {
        document.body.style.userSelect = 'none'
        const updateDraggingDistance = ({ clientX }) => {
          const width =
            spaceRef.current.parentElement.offsetWidth +
            prevClientXRef.current -
            clientX
          spaceRef.current.parentElement.style.width = `${width}px`
          prevClientXRef.current = clientX
        }
        const endDragging = () => setDragging(false)
        document.body.addEventListener('mousemove', updateDraggingDistance)
        document.body.addEventListener('mouseup', endDragging)
        return () => {
          document.body.style.userSelect = 'inherit'
          document.body.removeEventListener('mousemove', updateDraggingDistance)
          document.body.removeEventListener('mouseup', endDragging)
        }
      }
    }, [dragging])
    return (
      <DraggingSpace
        ref={spaceRef}
        onMouseDown={({ clientX }) => {
          setDragging(true)
          prevClientXRef.current = clientX
        }}
      />
    )
  },
}

export default insertionOfResizingSpace
